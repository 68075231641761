"use client"
import React, { useEffect, useState } from "react";
import BrokenLink from "@/components/broken-link";
import { useTranslation, I18nextProvider } from "react-i18next";
import { i18n } from "i18next";
import initTranslations from "@/lib/i18n/i18n";
import TranslationsProvider from "@/lib/i18n/provider";
// import initTranslations from "@/lib/i18n/i18n";

export default function Error() {
  const [resourceI18n, setResourceI18n] = useState<any>();
  const i18nNamespaces = ["errorPage"];

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    const { resources } = await initTranslations("id", i18nNamespaces);
    setResourceI18n(resources)
  }

  if (resourceI18n) {
    return (
      <TranslationsProvider
        namespaces={i18nNamespaces}
        locale={"id"}
        resources={resourceI18n}
      >
        <BrokenLink isServerError />
      </TranslationsProvider>
    );
  }
}
